<template>
  <section class="promo--detail-banner">
    <picture>
      <source :srcset="banner.web_image_url" type="image/webp" />
      <source :srcset="getFallbackImage(banner.web_image_url)" type="image/jpeg" />
      <img :src="getFallbackImage(banner.web_image_url)" :alt="`${banner.title} - Rentfix`" />
    </picture>
  </section>
</template>

<script>
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'banner-detail',
  mixins: [HelperMixin],
  props: ['banner'],
};
</script>
