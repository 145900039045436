<template>
  <default-layout>
    <div class="container promo--detail-container" v-if="banner">
      <banner :banner="banner" />
      <promo-header :banner="banner" />
      <div class="promo--detail-bottom">
        <div class="left--col">
          <promo-content :banner="banner" />
        </div>
        <div class="right--col">
          <action :banner="banner" />
        </div>
      </div>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import Banner from '@/components/promo/banner-detail';
import PromoHeader from '@/components/promo/header';
import PromoContent from '@/components/promo/content';
import Action from '@/components/promo/action';
import { mapState } from 'vuex';

export default {
  name: 'promo',
  components: {
    DefaultLayout,
    Banner,
    PromoHeader,
    PromoContent,
    Action,
  },
  async fetch({ store, route, error }) {
    try {
      await store.dispatch('banner/getBannerDetail', route.params.uuid);
    } catch (e) {
      error(new Error(e.message), 404);
    }

    const promo = store.state.banner.bannerDetail;
    const baseUrl = store.state.global.baseUrl;
    let title = promo.title;
    if (title) {
      // if (title.length > 55) title = title.substring(0, 52) + '...';
      title += ' | Rentfix';
    }

    let description = promo.description;
    if (description) {
      if (description.length > 310) description = description.substring(0, 307) + '...';
      description += ' | Rentfix';
    }

    const head = {
      title: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description,
        },
        {
          hid: 'og-description',
          name: 'og:description',
          content: description,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: baseUrl + route.fullPath,
          id: 'canonical',
        },
      ],
    };
    store.commit('head/SET_CONTENT', head);
  },

  computed: {
    ...mapState({
      banner: (state) => state.banner.bannerDetail,
    }),
  },
  methods: {},
  mounted() {},
};
</script>
